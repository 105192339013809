.containerForAll {
    display: flex;
    flex-direction: column;
    justify-content: center; 
    align-items: center;    
    height: 100vh;            
}

.containerLogo {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.comingSoon {
    color: black;
}

@media only screen
  and (max-width: 500px) {
    .placeholder-active {
        background-image: url("pictures/PlaceholderMobile.png");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        background-attachment: fixed;
      }

    .containerForAll {
        padding-top: 30vh;            /* Adjust this value as needed */
        justify-content: flex-start; 
    }

    .logoPlaceholder {
        width: 40%;
        z-index: 1;
    }

    .containerText {
        width: 74%;  // to wrap the text so it doesn't overlap the background
    }
    
    .comingSoon {
        font-weight: 700;
        font-size: 4.5vh;
        word-wrap: break-word;
    }
}

@media only screen
  and (min-width: 501px) {
    .placeholder-active {
        background-image: url("pictures/Placeholder.png");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        background-attachment: fixed;
      }

    .logoPlaceholder {
        // width: 30%;
        height: 35vh;
        z-index: 1;
    }
    
    .comingSoon {
        font-weight: 700;
        font-size: 7vh;
        margin-top: -10px;
    }
}
