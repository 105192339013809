@import url('https://fonts.googleapis.com/css2?family=Literata:ital,opsz,wght@0,7..72,200..900;1,7..72,200..900&display=swap');

:root {
  --primary: #d1fefc;
  --secondary: #ffe1e1;
  --accent: #ffeb37;
}

body {
  overflow-x: hidden;
}

.artist-card__img {
  max-width: 100%;
  display: block;
  grid-column: 1 / -1;
  grid-row: 1 / 3;
  align-self: center;
}

.about-container {
  display: flex;
  flex-direction: row;
  padding-top: 2vw;
}

.textTitlePhone {
  display: none;
}

.mobileImg {
  display: none;
}

.mobileTitle {
  display: none;
}

.aboutIntroduction {
  margin-left: 8vw;
  margin-right: 5vw;
}

.aboutTitle {
  margin-left: -2vw;
  margin-top: -40vw;
  z-index: 201;
  text-align: justify;
  font-family: "Literata";
  font-weight: 400;
  font-size: 3vw;
}

.aboutImg {
  width: 42vw;
  height: 42vw;
  margin-top: -2vw;
  margin-left: -8vw;
}

.TextBorderImg {
  height: 22vw;
  width: 27vw;
  z-index: 300;
  margin-top: -0.7vw;
  margin-right: -17vw;
}

.aboutTextBox {
  position: relative;
  text-align: center;
}

.frameImg {
  width: 60vw;
  height: 42vw;
  margin-top: -2vw;
  margin-left: -5vw;
}

.textDescription {
  color: #0C0A0B;
  font-family: "Literata";
  font-weight: 400;
  text-align: justify;
  text-justify: inter-word;
  width: 24vw;
  line-height: 1.4em;
  font-size: 1vw;
  position: absolute;
  top: 7.5vw;
  left: 24vw;

}

.mobileRectangle {
  display: none;
}

.mobileTextBorderImg {
  display: none;
}

@media (max-width: 1900px) {
  .textTitle {
    font-size: 4rem;
    color: #291C21;
  }
}


@media (max-width: 1000px) {
  .about-container {
    flex-direction: column !important;
  }

  .mobileImg {
    display: block;
    margin: 0 auto;
    max-width: 80%;
    height: auto;
    background-image: url('./Collage.png');
    background-repeat: no-repeat;
    background-size: 700px 300px;
    padding-bottom: 30px;
  }

  .mobileTitle {
    display: block;
    line-height: 1em;
    font-size: 8.5vw;
  }

  .TextBorderImg {
    display: none;
  }

  .frameImg {
    display: none;
  }

  .aboutTextBox{
    position: relative;
    display: flex;
    justify-content: center;
    width: 70%;
    height: 90vw;
    margin: 0 auto;
  }

  .mobileRectangle {
    display: block;
    width: 100%;
    height: auto;
    margin: 0 auto;
    position: absolute;
    //top: 0;
  }

  .TextBorderImg {
    display: block;
    width: 95%;
    height: 100%;
    margin: 0 auto;
    position: absolute;
    // top: 0;
  }

  .textDescription {
    
    color: #0C0A0B;
    font-family: "Literata";
    font-weight: 400;
    position: relative;
    top: 23vw;
    left: 0vw;
    width: 60vw;
    height: 70vw;
    line-height: 1.2em;
    font-size: 3vw;
  }

  .aboutTitle {
    position: absolute;
    text-align: left;
    margin-top: 1vw;
    margin-left: -10vw;
    line-height: 1.2em;
    font-size: 8vw;
  }

  .aboutImg {
    display: none;
  }

  .about-container {
    flex-direction: column;
    align-items: center;
  }

  .textTitle {
    font-size: 3.8rem !important;
  }

  .textTitlePhone {
    margin-top: 3vw;
    display: block;
    font-size: 4rem;
  }

  .phoneButton {
    display: block !important;
    margin-top: 10vw;
  }

  .aboutButton {
    display: none !important;
  }

  .aboutButton.mobile-btn {
    display: flex !important;
    justify-content: center !important;
    padding-bottom: 6vw;
  }
}

@media (max-width: 370px) {
  .textTitle {
    font-size: 1.7rem !important;
  }

  .textDescription {
    font-size: 0.64rem !important;
    color: #0C0A0B;
    font-family: "Literata";
    font-weight: 400;
    
  }

  .textTitlePhone {
    font-size: 2rem !important;
    margin-top: 10vw !important;
  }
}

@media (max-width: 300px) {
  .textTitle {
    font-size: 1.3rem !important;
  }

  .textDescription {
    font-size: 0.6rem !important;
    margin-top: 3vw !important;
    color: #0C0A0B;
    font-family: "Literata";
    font-weight: 400;
  }
}

.phoneButton {
  display: none;
}

.aboutButton {
  display: flex;
  justify-content: center;
  margin-top: 3vw;
}

.aboutButton.mobile-btn {
  display: none;
}

.aboutImage {
  width: 24vw;
  margin: 100px;
}

.registerLink {
  display: inline;
  color: rgba(12, 10, 11, 1);
  padding: 0 3px;
}

.textTitle {
  text-align: left;
  padding: 20px;
}

@supports (clip-path: circle(25%)) {
  .artist-card {
    clip-path: circle(0%);
    animation: circle-grow 2000ms 450ms forwards;

    &:hover .artist-card__img {
      clip-path: polygon(0% 0%, 100% 0%, 100% 50%, 100% 100%, 0% 100%);
    }
  }

  .artist-card__img {
    clip-path: polygon(18.11% 16.5%,
        50% 0%,
        75% 50%,
        54.09% 90.44%,
        13.7% 63.94%);
    transition: clip-path 400ms ease-in-out;
  }

  .artist-card__title {
    clip-path: inset(50% 50%);
    animation: heading-in 400ms 1000ms forwards;
  }
}

@keyframes circle-grow {
  to {
    clip-path: circle(100%);
  }
}

@keyframes heading-in {
  to {
    clip-path: inset(0% 0%);
  }
}

@supports (mix-blend-mode: darken) {
  .artist-card {
    background-image: linear-gradient(to top right,
        var(--primary) 25%,
        transparent),
      radial-gradient(circle at bottom right,
        transparent 15%,
        var(--secondary) 15%,
        var(--secondary) 20%,
        transparent 20%,
        transparent 22%,
        var(--secondary) 22%,
        transparent 30%);

    &::after {
      content: "";
      grid-column: 4 / 5;
      grid-row: 1 / 2;
      background: linear-gradient(to bottom left,
          var(--accent) 40%,
          transparent);
      mix-blend-mode: multiply;
      clip-path: circle(19%);
      transition: clip-path 400ms ease-in-out;
    }

    &:hover::after {
      clip-path: circle(100%);
    }
  }

}

@supports (-webkit-text-stroke: 1px black) {
  .artist-card__title {
    -webkit-text-stroke: 0.035em #001;
    color: transparent;
    text-shadow: 0.075em 0.075em var(--secondary);
  }
}

@media screen and (min-width: 25rem) {
  .artist-card__title::before {
    content: "";
    position: absolute;
    left: 0;
    top: 10%;
    width: 100%;
    height: 60%;
    clip-path: circle(3% at 95%);
    background-image: linear-gradient(150deg,
        var(--primary) 30%,
        var(--secondary) 50%,
        var(--accent));
    z-index: -1;
    transition: clip-path 400ms ease-in-out;

    .artist-card:hover & {
      clip-path: circle(100%);
    }
  }
}