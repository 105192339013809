@import url('https://fonts.googleapis.com/css2?family=Literata:ital,opsz,wght@0,7..72,200..900;1,7..72,200..900&display=swap');

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1040;
  @media only screen and (max-width: 767px) {
    cursor: pointer; 
  }
}

.closeButton {
  background: #AFAFAF;
  position: absolute;
  top: 10px;
  right: 0px;
  background: none;
  border: none;
  cursor: pointer;
}


.closeButton img {
  width: 20px;
  height: 20px;
}

.textContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: justify;
  padding: 0;
  font-family: 'Literata', serif;
  font-size: 18px;
  line-height: 1.5;
  color: #333;
  max-width: 90%;
  //margin: auto;
}

.textContainer p {
  margin: 0;
  padding: 0;
  font-size: 13px;

  @media only screen and (max-width: 500px) {
    font-size: 11px;
  }

  @media only screen and (max-width: 400px) {
    font-size: 10px;
  }
}


.flipCard {
  width: 450px;
  height: 600px;
  perspective: 1000px;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;

  @media only screen and (max-width: 500px) {
    width: 90%;
  }
}

.flipCardInner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
}

.flipped {
  transform: rotateY(180deg);
}

.flipCardFront, .flipCardBack {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #D9D9D9;
  padding: 10px;
  border-radius: 10px;
}

.flipCardFront {
  justify-content: space-between;
  padding-top: 40px;
  max-height: 600px;
}

.flipCardBack {
  justify-content: flex-start;
  padding-top: 40px;
  background: #D9D9D9;
  transform: rotateY(180deg);
}

.img {
  max-height: 200px;
  max-width: 200px;
  border-radius: 10px;
  width: 85%;
  margin-bottom: 5px !important;
  object-fit: contain;
  margin-top: 1px !important;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.titleTicketFront {
  font-size: 21px;
  font-family: 'Literata', serif;   
  font-weight: 400;
  margin-bottom: 10px;

  @media only screen and (max-width: 500px) {
    font-size: 24px;
  }
}

.rulesContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; /* Center content vertically */
  font-family: 'Literata', serif;
  font-size: 21px;
  font-weight: 600;
  color: black;
  line-height: 1.4;
  text-align: center;
  margin-top: 10px;
  width: 100%; /* Extend to 100% of the parent container */
  height: 100%; /* Ensure it takes up the full height of the parent container */
}

.rulesContainer p {
  margin-bottom: 10px;
}

.innerRules {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; 
  width: 100%; 
}


.titleTicketBack {
  font-size: 30px;
  font-family: 'Literata', serif;   
  font-weight: 400;
  margin-bottom: 10px;
  color: rgb(81, 3, 3);
}

.smallText {

  @media only screen and (max-width: 450px) {
    font-size: 10px;
  }
}