@import url('https://fonts.googleapis.com/css2?family=Literata:ital,opsz,wght@0,7..72,200..900;1,7..72,200..900&display=swap');

$secondary-color: #730303;
$primary-color: #434343;
$input-color: #AFAFAF;

:global .tab {
    color: $primary-color !important;
    padding: 0;
    padding-bottom: 1rem;
    font-family: 'Literata', serif;
    font-weight: 600;

    &.active {
        color: $secondary-color !important;
        background: transparent !important;
        border-color: transparent transparent transparent transparent !important;
        border-bottom: 3px solid $secondary-color !important;
        padding-bottom: 0.2rem;
        border-radius: 0 !important;
    }
}

.ticketsRow {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    // & > * {
    //     flex: 1 1 calc(25% - 1rem); /* 4 elements per row with some spacing */
    //     margin: 0.5rem;
    // }
}

.searchGroupInput {
    width: 259px;
    height: 30px;
    background-color: #730303;
    border-color: #730303;
    border-radius: 5vh;
    margin-right: 60px;
    margin-left: 60px;
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.250);
    font-family: 'Literata', serif;
}

.searchGroupInput ::placeholder {
    color: #AFAFAF;
    font-weight: 650;
    font-family: 'Literata', serif;
}

.filtersMenu {
    font-family: 'Literata', serif;
    flex-direction: row;
    @media only screen and (max-width: 575px) {
        flex-direction: column;
    }
}

.searchIcon {
    right: 10px;
    width: 30px;     
    height: 30px;   
    display: flex;
    border-radius: 5vh;
    align-items: center;
    justify-content: center;
    border-radius: 5vh !important;

    img {
        width: 80%;
        height: 80%;
        margin-right:6px;
        object-fit: contain;
    }
}

.filtersMenu1 {
    flex-direction: column;
}

.sortInput {
    height: 30px;
    font-size: 0.8rem;
    line-height: 1.25em;
    margin-left: 60px;
    margin-right: 60px;
    text-align: center;
    padding: 6px 12px;
    border: 0;
    width: 259px;
    border-color:$input-color;
    background-color: #730303;
    border-radius: 5vh;
    color: #AFAFAF;
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.250);
    font-weight: 650;

}

.navTabs {
    font-size: 18px;

}

.searchInput {
    height: 1.5rem;
    border: none;
    color: #AFAFAF;
    background-color: #730303;
    border-radius: 5vh;
    height: 30px;
    font-family: 'Literata', serif;
    font-size: 0.8rem;
}

.proba {
    font-family: 'Literata', serif;

    p {
        font-size: 17px;
        font-weight: 400;
    }
}