.h-100vh {
    position: relative;
}

.contactImg {
    width: 56vw;
    height: 46vw;

    @media screen and (max-width: 1000px) {
        width: 80%;
        height: auto;
    }
}

.my-5 {
    position: center;
    text-align: center;
    align-items: center;
    padding: 5%;
}

@media screen and (min-width: 700px){
    .contact-container {
        display: flex;
        align-items: center;
    }
}

@media (max-width: 1000px) {
    .contact-container {
        flex-direction: column;
    }
}