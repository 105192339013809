.img_container_static {
  z-index: 2;
  border: none !important;
  border-radius: 1vh;
  background-color:  #FFF7F1 !important;
  // max-width: 210px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40vh;
  height: 30vh;
  display: flex;
  justify-content: center;
  align-items: center;

  img{
    object-fit: contain;
    max-width: 100%;
    max-height: 100%;
    height: 20vh;
    width: 20vh;
  }
}

// modificarile facute pentru noul design de carusel
// incep aici

.img_container_carousel {
  z-index: 2;
  border: none !important;
  border-radius: 1vh;
  background: #D8D4CF !important;
  // max-width: 210px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 10vw;
  height: 10vw;
  border: 4px solid #D4772F!important;
  padding: 20px !important;
  img{
    object-fit: contain;
    max-width: 100%;
    max-height: 100%;
  }

  @media only screen and (max-width: 600px) {
    width: 27vw;
    height: 27vw;
  }
}

// si se termina aici

.card_container {
  z-index: 1;
  margin-left: -20px;
  border-radius: 3vh;
  background: #BCA8A1;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 0 28px 28px 0px;

  @media only screen and (max-width: 320px) {
    --bs-card-spacer-y: 0rem;
  }

  @media only screen and (min-width: 321px) {
    --bs-card-spacer-y: 0.5rem;
  }

  @media only screen and (min-width: 769px) {
    --bs-card-spacer-y: 0.75rem;
  }
}

.text_container {
  word-wrap: break-word;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 300px;

  @media only screen and (max-height: 700px) {
    max-height: 250px;
  }
}

.img {
  height: fit-content;
  width: fit-content;
  @media only screen and (max-width: 767px) {
      max-height: 100px;
      max-width: 200px;
  }
}