@import url('https://fonts.googleapis.com/css2?family=Literata:ital,opsz,wght@0,7..72,200..900;1,7..72,200..900&display=swap');


$light-background-color: #e3e3e3;
$dark-background-color: #d9d9d9;

.news-item {
  border: 1px solid black;
  border-radius: 10px;
  margin-right: 1.5vw;
  margin-bottom: 1vh;
  background-color: $light-background-color;
  white-space: pre-line;
  font-family: 'Literata', serif;
  font-weight: 400;
}

.news-container {
  display: flex;
  flex-direction: row;
  margin: 1vh 1vw;
  align-items: center;
}

.news-image {
  background-color: $dark-background-color;
  border: 1px solid black;
  width: 6vw;
  height: 6vw;
  padding: 0.5vw;
}

.news-info {
  display: flex;
  flex-direction: column;
  padding-left: 1.5vw;
}

.news-title {
  font-weight: bolder;
  font-size: 18px;
  margin-bottom: 8px;
}

.news-text {
  font-size: 14px;
  text-align: left;
  line-height: 1.2;
  max-height: 120px; 
  overflow-y: auto; 
  padding-right: 5px; /* Add padding to create space between text and scrollbar */
  scrollbar-width: thin;
  scrollbar-color: rgba(146, 146, 146, 0.4) #e3e3e3;
}


@media (max-width: 768px) {
  .news-item {
    margin: 10px 0px;
  }
  .news-image {
    width: 90px;
    height: 90px;
    margin-left: 10px;
    margin-right: 10px;
  }
  .news-title {
    font-size: 14px;
  }
  .news-text {
    font-size: 13px;
  }
}
