.carousel-container {
  border: 2px solid rgba(0, 0, 0, 0);
  min-height: 30.3vw;
  background-color: rgba(148, 135, 135, 0.055); /* Fundal semi-transparent */
  backdrop-filter: blur(10px); /* Aplică blur */
  -webkit-backdrop-filter: blur(10px); /* Compatibilitate Safari */
  border-radius: 10px; /* Colțuri rotunjite pentru un look modern */
  filter: drop-shadow(4px 4px 4px rgba(0, 0, 0, 0.5));
}

.mt-1 {
  font-family: 'Abhaya Libre';
  font-weight: 400;
  font-size: 3vw;
  filter: drop-shadow(4px 4px 4px rgba(0, 0, 0, 0.3));
  padding: 20px;
}

.carousel-control-next,
.carousel-control-prev {
  width: 3rem;
  background: #cecece;
  backdrop-filter: blur(5px);
  opacity: 0.7;
  border-radius: 5px;
} 

.carousel-control-prev-icon {
  background-image: url('./left.svg');
  filter: none !important;
}

.carousel-control-next-icon {
  background-image: url('./right.svg');
  filter: none !important;
}

.item-container {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 1rem 6rem;
  height: 100%;
  width: auto;
}

@media (max-width: 1000px) {
  .mt-1 {
    font-size: 6vw;
  }
}

@media only screen and (max-width: 767px) {
  .carousel-container {
    width: 90vw;
  }

  // .item-container {
  //   display: grid;
  //   grid-template-columns: 1fr 1fr;
  //   padding: 1.8rem 2.9rem;
  // }
}

@media only screen and (min-width: 768px) {
  .carousel-container {
    width: 70vw;
  }
}