.portrait-modal {
    .modal-content {
        background-color: #AFAFAF;
        // the frame image is very slightly off 3/4 aspect ratio
        aspect-ratio: 3 / 4 !important;
        // so technically, this stretches it a bit, but it's not noticeable
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-position: center;
        border: none;
        border-radius: 0;
        max-width: 95vw;
        margin: auto;
        height: 90vh;
    }
    .modal-header {
        border: none;
        height: 7%;
    }
    .modal-body {
        padding-top: 0%;
        padding-bottom: 14%;
        padding-left: 5%;
        padding-right: 5%;
        container-type: size;
    } 
    input, textarea {
        border: none;
        border-radius: 0.3rem;
        background-color: #D8D4CF;
        padding: 0.3rem 0.6rem;
        &:not(:read-only) {
            filter: drop-shadow(0px 4px 2px rgba(0, 0, 0, 0.25));
        }
        &:read-only {
            pointer-events: none;
        }
    }
    textarea {
        resize: none;
        overflow-y: auto;
    }
}