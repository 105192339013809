@import url('https://fonts.googleapis.com/css2?family=Literata:ital,opsz,wght@0,7..72,200..900;1,7..72,200..900&display=swap');

$base-background-color: #dadada;
$light-background-color: #e3e3e3;
$dark-background-color: #d9d9d9;
$base-accent-color: #510303;
$light-accent-color: #730303;
$make-post-background-color: #d7d7d7;

.blog-background {
  background-color: $base-background-color;
  background-image: url('./images/blog_background.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100vw;
  height: calc(
    100vh - 196px
  ); // ia inaltimea viewport ului fara navbar si header, daca ele se modifica trebuie modificate si nr de pixeli
  font-family: 'Literata', serif;
  display: flex;
  flex-direction: column;
}

.desktop-divs {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 100%;
}

.start-post-section,
.news-section {
  width: calc(88vw / 3);
  min-height: 50vh;
}

.view-post-section {
  width: calc(100vw - 2 * 88vw / 3);
  min-height: 50vh;
  padding-left: 6vw;
  padding-right: 6vw;
}

.start-post-section {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  margin-top: 40px;
}

.start-post-container {
  border: 1px solid black;
  height: 20vh;
  width: 16vw;
  border-radius: 10px;
  background-color: $light-background-color;
}

.start-post-image {
  width: 100%;
  height: 60%;
  border: 1px solid black;
  border-radius: 10px;

  @media (max-width: 770px) {
    height: 75%;
  }
}

.start-post-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.start-post-main-button {
  margin-top: 10px;
  height: 5vh;
  max-height: 50px;
  border-radius: 20px;
  padding: 5px 0px;
  background-color: $light-accent-color;
  color: white;
  text-align: left;
  font-size: min(1.1vw, 18px);
  padding-left: 8%;
  width: 90%;
  box-shadow: 4px 4px 4px #afafaf;
  font-size: min(18px, 3vh);

  &:hover {
    background-color: $light-accent-color;
  }
}

.start-post-buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1.5vw;
  margin-bottom: 5px;
}

.start-post-camera-button {
  width: 5vh;
  height: 5vh;
  max-width: 50px;
  max-height: 50px;
  background-image: url('./images/camera_icon.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-color: transparent;
  background-position: center;
  padding: none;
  border: none;
  cursor: pointer;

  &:hover {
    background-color: transparent;
  }
}

.start-post-gallery-button {
  width: 5vh;
  height: 5vh;
  max-width: 50px;
  max-height: 50px;
  background-image: url('./images/gallery_icon.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-color: transparent;
  background-position: center;
  padding: none;
  border: none;
  cursor: pointer;

  &:hover {
    background-color: transparent;
  }
}

.prize-container {
  border: 1px solid black;
  height: auto;
  width: 16vw;
  margin-top: 2vh;
  border-radius: 10px;
  background-color: $base-background-color;
  background-image: url('./images/likes_background.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
}
.cropped-warning-background {
  border: 1px solid black;
  border-radius: 10px;
  margin-top: 2vh;
  background-color: $light-background-color;
  white-space: pre-line;
  width: 16vw;
}

.cropped-warning-container {
  display: flex;
  flex-direction: row;
  margin: 1vh 1vw;
  align-items: center;
}

.cropped-warning-container-image {
  background-color: $base-background-color;
  border: 1px solid black;
  width: 4vw;
  height: 4vw;
  padding: 0.5vw;
}

.cropped-warning-info {
  display: flex;
  flex-direction: column;
  padding-left: 1.5vw;
}

.cropped-warning-title {
  font-weight: bolder;
  font-size: min(19px, 1.2vw);
}

.cropped-warning-text {
  font-size: min(16px, 1vw);
  text-align: left;
  line-height: 1.2;
}

.prize-text {
  font-weight: 700;
  font-size: min(1.1vw, 21px);
  padding: 2vw 1.1vw;
  line-height: 1.2;
}

.news-section {
  overflow-y: auto;
  height: 100%;
}

.news-item:first-child {
  margin-top: 20px;
}

.view-post-section {
  overflow-y: auto;
  height: 100%;
}

.sort-post-container {
  display: flex;
  justify-content: space-between;
  margin-top: 38px;
  margin-bottom: 20px;
  padding-left: 10%;
  padding-right: 10%;
}

.blog-filter-container {
  background-color: $light-background-color;
  border-radius: 10px;
  padding: 2px 15px 4px 15px;
  border: 1px solid black;
  font-size: 16px;
  flex-shrink: 1;
  color: black;
}

.blog-filter-container.active {
  background-color: $light-accent-color;
  color: white;
}

/* Hide scrollbar for Chrome, Safari, and Opera */
.view-post-section::-webkit-scrollbar,
.news-section::-webkit-scrollbar {
  display: none; /* Hide scrollbar */
}

/* Hide scrollbar for Internet Explorer, Edge */
.view-post-section,
.news-section {
  -ms-overflow-style: none; /* Internet Explorer and Edge */
}

/* Hide scrollbar for Firefox */
.view-post-section,
.news-section {
  scrollbar-width: none; /* Firefox */
}

///MAKE A POST SECTION
///
.make-post-dark-background {
  z-index: 1040;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
}

.make-post-container {
  padding: 0px 0px 25px 0px;
  margin: auto;
  width: 20vw;
  height: 28vw;
  background-color: $make-post-background-color;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border: 1px solid black;
  border-radius: 10px;
}

.make-post-top-button-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 5px 10px;
}

.make-post-top-left-button-container {
  display: flex;
  justify-content: flex-start;
  gap: 20px;
  align-items: center;
}

.post-button {
  height: 35px;
  max-height: 50px;
  border-radius: 20px;
  background-color: $light-accent-color;
  color: white;
  text-align: left;
  font-size: 18px;
  padding: 0px 15%;
  width: auto;
  box-shadow: 4px 4px 4px #afafaf;
  font-family: 'Literata', serif;

  &:hover {
    background-color: $light-accent-color;
  }
}

.make-post-exit-button {
  font-size: 30px;
  padding: 5px;
  background-color: transparent;
  background-position: center;
  padding: none;
  border: none;
  cursor: pointer;
  color: black;

  &:hover {
    background-color: transparent;
  }
}

.make-post-main-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
  gap: 30px;
}

.make-post-image-container {
  border: 1px solid black;
  //border-radius: 5px;
  overflow: hidden;
}

.make-post-image {
  width: 100%;
  aspect-ratio: 1 / 1;
  object-fit: cover;
}

.make-post-bottom-container {
  margin: 5px 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.caption-input {
  background-color: transparent;
  border: 0px;
  font-size: 16px;
  font-weight: 400;
  padding: 0px;

  &::placeholder {
    color: black;
  }

  &:focus {
    outline: none;
  }
}

.news-select-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-right: 1.5vw;
  margin-top: 25px;
  margin-bottom: 9px;
}

.news-category-button {
  color: var(--light-accent-color);
  background: transparent;
  border: none;
  font-size: 1rem;
  padding: 8px 1px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  &:hover {
    color: var(--light-accent-color);
  }
}

@media (max-width: 1300px) {
  .news-category-button {
    font-size: 0.8rem;
  }
}

@media (max-width: 1000px) {
  .news-category-button {
    font-size: 0.7rem;
  }
}

.news-category-button.active {
  color: #730303;
  font-weight: bold;
  text-decoration: underline;
  text-decoration-thickness: 3px;
  text-underline-offset: 4px;
}

//for MOBILE
@media (max-width: 768px) {
  //buttons
  .mobile-button-container {
    display: flex;
    justify-content: space-between;
    margin: 5px 30px;
  }

  .mobile-blog-button-container,
  .mobile-news-button-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: auto;
  }

  .mobile-blog-button,
  .mobile-news-button {
    border-radius: 20px;
    padding: 8px 25px;
    background-color: $light-accent-color;
    color: white;
    text-align: center;
    font-size: 16px;
    box-shadow: 4px 4px 4px #afafaf;
    margin-top: 5px;

    &:hover {
      background-color: $light-accent-color;
    }
  }

  //for all sections
  // + do not make posts and news scrollable for mobile

  .blog-background {
    height: auto;
  }

  .desktop-divs {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: auto;
  }

  .start-post-section,
  .view-post-section,
  .news-section {
    overflow-y: unset;
    min-height: auto;
    height: auto;
  }

  //blog post mobile section
  .start-post-section {
    width: 100%;
    margin-top: 15px;
    align-items: center;
  }

  .start-post-container {
    height: 28vh;
    width: 95%;
  }

  .start-post-main-button {
    font-size: min(18px, 2vh);
    padding-top: 8px;
    padding-bottom: 8px;
  }

  .start-post-buttons {
    gap: 30px;
  }

  .view-post-section {
    align-self: center;
    width: 85%;
    padding-left: 0vw;
    padding-right: 0vw;
  }

  //news mobile section
  .news-mobile-title {
    font-size: 35px;
    font-weight: 700;
  }

  .news-section {
    width: 85%;
    margin: 10px;
  }

  .prize-container {
    width: 100%;
    height: 150px;
    margin-top: 15px;
    margin-bottom: 10px;
  }

  .prize-text {
    font-size: 20px;
    padding: 20px 20px;
  }

  //for make a post mobile
  .make-post-container {
    width: 95vw;
    height: 130vw;
    margin: auto;
  }

  .news-select-container {
    margin-top: 10px;
  }

  .news-select-container > button {
    font-size: min(18px, 3.5vw);
  }
}

//media queries to fix make post image height and width

@media (min-width: 500px) {
  .make-post-container {
    margin: auto;
    width: 60vw;
    height: 80vw;
  }
}

@media (min-width: 768px) {
  .make-post-container {
    margin: auto;
    width: 40vw;
    height: 55vw;
  }
}

@media (min-width: 1100px) {
  .make-post-container {
    margin: auto;
    width: 30vw;
    height: 40vw;
  }
}

@media (min-width: 1400px) {
  .make-post-container {
    margin: auto;
    width: 30vw;
    height: 38vw;
  }
}
