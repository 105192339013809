//REACT BIG CALENDAR (.rbc)!!!!!

// Styling first row of calendar div
.rbc-header{
    border-bottom: 1px solid black !important;
    border-right: 1px solid black !important;
    text-transform: capitalize;
    font-weight: bold;
    font-size: 1.5rem !important;
    color: #434343 !important;
    background-color: #D8D4CF;
    padding: 5%;
}

.rbc-month-view .rbc-header:last-child {
    border-right: none !important;
}

.rbc-allday-cell {
    // comment for all day events
    display: none !important;
}

.rbc-day-bg {
    background-color: #D8D4CF !important;
    border-right: 1px solid black !important; 
    border-bottom: 1px solid black !important;
}

.rbc-time-gutter {
  //Vertical line on the right edge of the gutter
  border-right: 1px solid black !important;
  background-color: #D8D4CF !important;
}

.rbc-event{
    border: none !important;
    display: flex;
    justify-content: center;
}

// Styling event
.rbc-event-content {
    padding-left: 2px;
    color: #ffffff;
}

.rbc-event-label {
    color: #FBFAFA;
}

// Styling the title
.rbc-toolbar-label {
    text-transform: capitalize;
    font-weight: bold;
    font-size: 2.5rem;
}

// Styling each date in calendar
.rbc-button-link {
    font-size: 1.5rem !important;
    font-weight: bold;
}

.rbc-month-view, .rbc-time-view{
    border-radius: 10px;
    overflow: hidden !important;
    border: 1px solid black !important;
}

.calendar-div {
    padding: 5%;
}

// Styling out of range days
.rbc-off-range-bg {
    background-color: #E3E3E3 !important;
    border-right: 1px solid black !important;
    border-bottom: 1px solid black !important;
}

.rbc-today {
    background-color: #AFAFAF !important;
}

.rbc-month-row:last-child .rbc-day-bg {
    border-bottom: none !important;
}

.rbc-day-bg:last-child {
    border-right: none !important;
}

// For out of range days in last row/column
.rbc-month-row:last-child .rbc-off-range-bg {
    border-bottom: none !important;
}

.rbc-off-range-bg:last-child {
    border-right: none !important;
}

// Styling the toolbar buttons
.rbc-btn-group button{
    border: none !important;
    border-radius: 0% !important;
    color: #434343 !important;
} 

.rbc-toolbar button:focus{
    background-color: transparent !important;
    color: #730303 !important;
}
.rbc-toolbar button:hover{
    background-color: transparent !important;
    color: #730303 !important;
}

.rbc-btn-group {
    background-color: transparent !important;
    color: #730303 !important;
}

.rbc-toolbar button:active, .rbc-toolbar button.rbc-active {
    box-shadow: none !important;
    background-color: transparent !important;
    border-bottom: solid 2px #730303 !important;
    color: #730303 !important;
}

.rbc-btn-group button:active {
    border-bottom: solid 2px #730303 !important;
    color: #730303 !important;
}

// Styling the show more events button
.rbc-show-more {
    background: transparent !important;
    color: #434343 !important;
}

.rbc-show-more:hover {
    background: transparent !important;
    color: #730303 !important;
}

.rbc-toolbar {
    display: flex;
    flex-wrap: wrap;
}

.rbc-agenda-view table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
}

.rbc-agenda-view table thead tr th {
  background-color: #D8D4CF;
  color: #434343;           
  font-weight: bold;
  padding: 0.5rem 1rem;
  border: 1px solid black; 
  text-align: left;
}

.rbc-agenda-view table tbody tr {
  border: 1px solid black;
}

.rbc-agenda-view table tbody td.rbc-agenda-date-cell,
.rbc-agenda-view table tbody td.rbc-agenda-time-cell {
  background-color: #E3E3E3; 
  color: #434343;
  padding: 0.5rem 1rem;
  border-right: 1px solid black;
}

.rbc-agenda-view table tbody td.rbc-agenda-event-cell {
  background-color: #730303; 
  color: #ffffff;        
  padding: 0.5rem 1rem;
  padding:10px;
}

.rbc-agenda-empty-cell {
  color: #888; 
}

.rbc-agenda-content {
  border: 1px solid black;
}

// Styles for weekly view
.rbc-time-view {
    background-color: #D8D4CF !important;
}

.rbc-time-header {
    background-color: #D8D4CF !important;
}

.rbc-time-content {
    background-color: #D8D4CF !important;
    margin-left: 0px;
}

.rbc-time-slot {
    background-color: #D8D4CF !important;
}

.rbc-time-gutter {
    background-color: #D8D4CF !important;
}

.rbc-timeslot-group {
    background-color: #D8D4CF !important;
}

.rbc-time-column {
    background-color: #D8D4CF !important;
}

// This ensures the time slots also have the same background
.rbc-day-slot {
    background-color: #D8D4CF !important;
}

// Add borders to weekly view components
.rbc-time-view {
    background-color: #D8D4CF !important;
}

.rbc-time-header {
    background-color: #D8D4CF !important;
}

.rbc-time-content {
    background-color: #D8D4CF !important;
    border-top: 1px solid black !important;
}

.rbc-time-slot {
    background-color: #D8D4CF !important;
    
}

.rbc-timeslot-group {
    background-color: #D8D4CF !important;
    border-bottom: 1px solid black !important;
}

.rbc-time-column {
    background-color: #D8D4CF !important;
    border-right: 1px solid black !important;
}

.rbc-day-slot {
    background-color: #D8D4CF !important;
    border-right: 1px solid black !important;
}

// Add borders to time gutter
.rbc-time-gutter {
    background-color: #D8D4CF !important;
}

// Add borders to time header cells
.rbc-time-header-content {
    border-left: 1px solid black !important;
}


  @media (max-width: 1000px) {
    .rbc-toolbar {
      flex-direction: column;
    }

    .rbc-header {
        font-size: 14px !important;
    }

    .rbc-button-link {
        font-size: 14px !important;
    }
    
    .rbc-event-content {
        font-size: 8px !important;
    }
  }


  .rbc-time-content::-webkit-scrollbar {
    display: none;
}

/* Ascunde scrollbar-ul pentru Firefox */
.rbc-time-content {
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE și Edge */
} 

.rbc-time-header.rbc-overflowing {
    margin-right: 0px !important;
  }
  
.rbc-events-container {
    margin-right: 0px !important;
}