.containerAll {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.titleEchipa {
    font-size: 55px;
    font-weight: 600;
    line-height: 80px;
    letter-spacing: 0em;
    text-align: center;
    color: #362121;
    margin: 0;
    text-shadow: 3px 3px 3px rgba(0, 0, 0, 0.1);
}

.imageRow {
    // max-height: 10vh;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    // width: 100%;
}

.containerImage {
    display: flex;
    flex-direction: column;
    justify-content: row;
    border: none;
    background: none;
    width: 18%;
    margin-left: 2vh;
    margin-right: 2vh;
}

.name {
    color: #434343;
    font-size: 18px;
    font-weight: 650;
    margin: 0;
    flex: 2;
}

.positionInTeam {
    color: #362121;
    font-size: 15px;
    font-weight: 650;
    margin-top: 2px;
    flex: 2;
}

.teamImages {
    flex: 1;
    width: 100%;
    height: 100%;
    // margin-left: 40px;
    // margin-right: 40px;
    margin-bottom: 15px;
    border-radius: 7px;
    border: 1.5px solid black;
    object-fit: contain;
}

@media only screen
  and (max-width: 1024px) {
    .name{
        font-size: 15px;
    }
    .positionInTeam {
        font-size: 13px;
    }

    .containerImage {
        width: 25%;
    }
  }

@media only screen
  and (max-width: 768px) {
    .name{
        font-size: 13px;
    }
    .positionInTeam {
        font-size: 12px;
    }
    .containerImage {
        width: 25%;
    }
  }

@media only screen
  and (max-width: 450px) {
    .name {
        font-size: 17px;
    }
    .containerImage {
        width: 100%;
        margin-left: 3vh;
        margin-right: 3vh;
    }
    .positionInTeam{
        font-size: 15px;
    }
  }
