.nav-link {
    -webkit-transition-property: none;
    -moz-transition-property: none;
    -o-transition-property: none;
    transition-property: none;
    font-family: Literata;
    font-size: 14px;
}

@media (max-width: 1200px) {
    .nav-link {
        font-size: 12px;
    }
}
.active-scroll-spy{
    font-weight: bold;
}

.navbar-nav {
    height: 100%;
}

.navbar-logo {
    height: 80px;
    width: 80px;
    
}

@media (max-width: 767px) {
    .navbar-nav {
        position: relative;
        min-height: 100%;
        min-width: 100%;
        background-color: #510303;
        left: 0;
    }

    .navbar-logo {
        height: 50px;
        width: 50px;
    }
}

.navbar-shadow {
    border-bottom: 1.5px solid rgba(0, 0, 0, 1);
    box-shadow: 0px 0.5px 2px black;
    width: 100%;
}

.right {
    display: flex;
    justify-content: right;
    align-items: right;
   
}

.navbar-toggler-white {
    background-color: transparent;
    .navbar-toggler-icon {
        background-image: url("../../assets/Icon.png");
    }
    border: none;
}
.navbar-toggler-white:focus,
.navbar-toggler-white:active {
    outline: none !important;
    box-shadow: none !important;
}