@import url('https://fonts.googleapis.com/css2?family=Literata:ital,opsz,wght@0,7..72,200..900;1,7..72,200..900&display=swap');

.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  //border: 1px solid #000;
  border-radius: 0px;
  border: 1px solid black;
  background: #AFAFAF;
  color: #000;
  font-family: Literata;
  font-weight: 400;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  margin: 0.5rem;
  transition: all 300ms;

  &:hover {
    transition:all 300ms;
    background-color: #D8D4CF;
  }

  &.large {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-top: 0.9rem;
    padding-bottom: 0.7rem;
  }

  &.small {
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 0.4rem;
    padding-bottom: 0.4rem;
    border-radius: 10px;
    margin: 0;

    .logo {
      width: 1.6rem;
      height: auto;
      margin-right: 0.5rem;
    }

    @media only screen and (max-width: 990px) {
      .logo {
        width: 1.4rem;
        margin-right: 0.25rem;
      }

      .text {
        font-size: 1.5rem;
      }
    }

    @media only screen and (max-width: 576px) {
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      padding-left: 0.3;
      padding-right: 0.3;
      font-size: 0.75rem;
    }
  }

  .text {
    @media only screen and (max-width: 991px) {
      font-size: 1.3rem !important;
      margin: 0px !important;
    }

    @media only screen and (max-width: 700px) {
      font-size: 1rem !important;
      margin: 0px !important;
    }
  }

  .text {
    color: #000
  }
}