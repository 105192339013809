.tickets_container {
    display: flex;
    justify-content: center;
    padding: 20px;
    //max-width: 45vh;
    height: 500px;
    width: 400px;
}



.event_card {
    position: relative;
    background-color: #e4e4e4;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 270px;
    padding: 15px;

    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    border-radius: 10px;
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.250);
    flex-grow: 1; /* Makes the card take the full height of the container */
}


.event_card_content {
    position: relative;
    padding: 0px;
    display: flex;
    flex-direction: column;
    justify-content: space-between; /* Adds space between child elements */
    align-items: center;
    width: 100%;
    height: 100%;
}


.div_event_img {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 180px;
    overflow: hidden;

}
.event_img {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60%; 
    max-height: 180px;
}

// bilete ramase
.event_card_text {
    text-align: center;
    font-size: 16px;
    color: #333;
    margin: 5px 0;
}
.event_card_date {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    color: #333;
    font-weight: bold;
    margin: 8px 0;
    padding-right: 15px;
}

.event_card_tura {
    text-align: center;
    font-size: 14px;
    color: #333;
    font-weight: bold;
    margin: 8px 0;
    padding-right: 10px;
}

.name_and_type {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    font-size: 14px;
    color: #555;
    margin-top: 8px;
}


.pentru_nume_eveniment {
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    color: #222;
    // margin-top: 10px;
}

.class_for_pinpoint_and_tura_and_type {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    font-size: 14px;
    //color: #555;
    margin-top: 10px;
}

    // @media screen and (max-width: 600px) {



    //     .event_img {
    //         height: 200px;
    //         width: 100%;
    //         object-fit: cover;
    //     }

    //     .event_card_text {
    //         font-size: 3.7vw;
    //     }

    // }

@media screen and (max-height: 760px) {
    .tickets_container {
        height: 68vh;
        max-width: 55vh;
    }

    .pentru_nume_eveniment {
        font-size: 12px;
    }


}