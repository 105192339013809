@import url('https://fonts.googleapis.com/css2?family=Literata:ital,opsz,wght@0,7..72,200..900;1,7..72,200..900&display=swap');

.books-container {
  object-fit: contain;
  // height: min(30vh, 30vw);
  position: relative;
  
}

.book-item {
  max-width: 100%;
  max-height: 100%;
  margin-bottom: 1rem;
  
}

.book-image {
  //border-radius: 10px;
  border: none;
  background-color: transparent;
  aspect-ratio: 1 / 1;
  padding: 0.5rem;

  img {
    object-fit: contain;
    max-width: 100%;
    max-height: 100%;
  }
}

.pending img {
  filter: blur(5px);
  //background-color: rgba(0, 0, 0, 0.2);
}

.ribbon {
  position: absolute;
  top: 110px;
  left: -1  0px;
  background: #730303;
  color: white;
  padding: 5px 40px;
  font-weight: bold;
  font-size: 14px;
  text-transform: uppercase;
  transform: rotate(-45deg);
  transform-origin: top left;
  z-index: 10;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
}


.book-hearts {
  .heart {
    object-fit: contain;
    height: 100%;
    margin-left: 5%;
    margin-right: 5%;
  }

  span {
    font-size: 1.3rem;
    line-height: 100%;
    color: white;
  }
}

.modal-top {
  position: relative;
  margin-top: 5%; // fallback for browsers that don't support cqh units
  margin-top: 2.5cqh;
  position: relative;
  padding-bottom: 5%;
  padding-bottom: 2.5cqh;
  text-align: center;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 0.1rem;
    background-color: #0a0708;
    border-radius: 10px;
  }

  & > h2 {
    text-align: center;
    font-size: 6cqh;
  }
}

.book-reviews {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100% - 15cqh);
  width: 100%; // Stretch the width to the size of the modal
  padding-top: 10px;
}

.header-reviews {
  display: flex;
  justify-content: center;
  align-items: center;
}

.reviewList {
  height: calc(100% - 10cqh);
  background-color: #d8d4cf;
  border-radius: 10px;
  margin-bottom: 0.5rem;
  overflow-y: auto;

  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: normal;

  &::-webkit-scrollbar {
    width: 8px;
    padding-left: 10px;
    margin-left: 20px;
  }

  &:hover::-webkit-scrollbar-thumb {
    background-color: rgba(146, 146, 146, 0.4);
    border-radius: 4px;
  }
}

.book-suggestion {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100% - 12cqh);
  width: 100%; 
  padding-top: 10px;

  input {
    width: 100%;
    margin-bottom: 1rem;
  }
}

.description {
  height: calc(100% - 10cqh);
  background-color: #d8d4cf;
  border-radius: 10px;
  margin-bottom: 0.5rem;
  overflow-y: auto;

  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: normal;

  &::-webkit-scrollbar {
    width: 8px;
    padding-left: 10px;
    margin-left: 20px;
  }

  &:hover::-webkit-scrollbar-thumb {
    background-color: rgba(146, 146, 146, 0.4);
    border-radius: 4px;
  }
}

.font-style {
  font-family: "Literata", "serif";
  font-weight: 400;
}