.quizBackground {
    background-image: url('./BackgroundQuiz.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100vw;
    min-height: 85vh; 
    display: flex;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 470px) {
        background-image: url('./back2.png');
        
    }
}

.sectionTitle {
    color: #352D2D;
    font-family: "Literata";
    font-weight: 733;
}

.quizContainer {
    background-color: #AFAFAF;
    border-radius: 1vh;
    border-color: #0000;
    padding: 50px;
    height: 555px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .question {
        background-color: #D8D4CF;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
        min-height: 6rem;
        min-width: 23rem;
        border: 1px solid #23121A;
        margin: 30px;
        margin-bottom: 10px;


        p {
            color: #434343;
            font-family: Abhaya Libre, serif;
            font-size: 11px;
            font-weight: 700;
            padding-left: 5px;
            padding-right: 5px;
            margin-top: auto;
            margin-bottom: auto;
        }
    }

    .response {
        background-color: #D8D4CF;
        min-width: 23rem;
        min-height: 1rem;
        display: flex;
        justify-content: center;
        border-radius: 10px;
        border: 1px solid #23121A;
        color: #434343;
        font-family: Abhaya Libre;
        font-size: 11px;
        font-weight: 700;
        margin-left: 30px;
        margin-right: 30px;
        margin-bottom: 10px;
    }

    // CHecked response
    input:checked+label {
        background-color: rgb(190, 185, 189);

    }

    // Submit button
    .submit {
        background-color: #E3E3E3;
        min-height: 1rem;
        display: flex;
        justify-content: center;
        border-radius: 22px;
        font-family: Abhaya Libre;
        font-size: 15px;
        font-weight: 700;
    }

    .submitedMessage {
        font-family: Abhaya Libre;
        font-size: 16px;
        line-height: 1.5rem;
        font-weight: 700;
        margin: 15px;
    }

    @media (min-width: 768px) {
        padding: 2rem;
        width: 30rem;
        max-width: 800px;

        .question {
            border: 2px solid #23121A;

            p {
                font-size: 22px;
            }
        }

        .response {
            border: 2px solid #23121A;
            font-size: 19px;

        }

        .submit {
            border: 3px solid #472C2C;

            font-size: 20px;
        }

        .submitedMessage {
            font-family: Abhaya Libre;
            font-size: 28px;
            line-height: 2rem;
            font-weight: 700;
        }
    }

    @media (max-width: 500px) {
        width: 90vw;
        height: 105vw;

        .question {
            min-width: 70vw;
            min-height: 20vw;
        }

        .response {
            min-width: 70vw;
            min-height: 10vw;
        }
    }
}