.form {
    max-width: 700px;
}

.field {
    background: #D8D4CF;
    border: 1px solid #23121A;
    border-radius: 1vh;
    min-width: 350px;
    min-height: 50px;

    @media screen and (max-width: 1000px) {
        min-width: 250px;
    }
}

.custom-card {
    padding-top: 5%;
    padding-bottom: 2%;
    background-color: #AFAFAF;
    width: 67.5vh;
    height: 80.5vh;
    max-width: 470px;
    max-height: 550px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #23121A;
    border-radius: 1vh;
    filter:drop-shadow(4px 4px 4px rgba(0, 0, 0, 0.3));

    @media screen and (max-width: 1000px) {
        padding-top: 30%;
        padding-bottom: 30%;
        margin-left: 1vh;
        margin-right: 1vh;
        width: 30vh;
        height: 50vh;
        min-width: 300px;
        min-height: 450px;
        max-width: 485px;
        max-height: 700px;
    }


}

.sso-alt-text {
    font-family: "Literata";
    font-size: 1.7vh;
    margin-top: 1.5rem;

    @media screen and (max-width: 990px) {
        margin-top: 2px;
        margin-bottom: 2px;
    }
}

.forgot-password {
    font-family: "Literata";
    font-size: 2vh;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
    margin-top: 2rem;

    @media screen and (max-width: 990px) {
        margin-top: 1rem;
    }
}

.title-desktop {
    font-family: "Literata";
    font-size: 2.6vh;
    font-weight: 400;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);

    // @media screen and (max-width: 990px) {
    //     display: none;
    // }
}

.title-mobile {
    display: none;
    font-family: "Literata";
    font-size: 3.5vh;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
    margin-bottom: 2.5vh;

    // @media screen and (min-width: 990px) {
    //     display: none;
    // }
}