html {
  /* scrollspy */
  scroll-padding-top: 100px; /* height of your navbar */
  scroll-behavior: smooth;
}
@media only screen and (max-width: 767px) {
  html {
    /* scrollspy */
    scroll-padding-top: 70px;
    /* scrollspy */
    scroll-padding-top: 70px;
  }
}
body {
  margin: 0;
  font-family: 'Abhaya Libre', '-apple-system', 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-family: 'Abhaya Libre', '-apple-system', 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* background */
  background-color: #DADADA;
}

code {
  font-family: Playfair Display, source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.color-primary {
  color: #000000;
}

.color-secondary {
  color: #edebe9;
}

/* Placeholder style */
::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #574242;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #574242;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #574242;
}
