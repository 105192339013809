@function get-vw($target) {
    $vw-context: (1000*.01) * 1px;
    @return ($target/$vw-context) * 1vw;
}

.login-background {
    background-image: url('../components/Background_MOFT.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100vw;
    min-height: 85vh; 
    display: flex;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 990px) {
        background-image: none;
    }
}

// .login-form-background {
//     background-image: url('../components/Rama_Login.png');
//     background-size: contain;
//     background-position: center;
//     background-repeat: no-repeat;
//     background-color: #EDEBE9;
//     margin-top: 20vh;
//     margin-bottom: 20vh;
//     width: 63vh;
//     height: 75vh;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//   }

.imgContainer {
    background-color: rgba(255, 255, 255, 0.8);
    padding: 20px;
    border-radius: 10px;
    z-index: 1;
}

button {
    padding: 10px 20px;
    border-radius: 5px;
    // background-color: #007bff;
    color: white;
    border: none;
    cursor: pointer;
}