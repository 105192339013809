@import url('https://fonts.googleapis.com/css2?family=Literata:ital,opsz,wght@0,7..72,200..900;1,7..72,200..900&display=swap');

$comment-section-background-color: #afafaf;
$light-accent-color: #730303;

.post-item {
  width: 100%;
  border-radius: 1px;
  font-family: 'Literata', serif;
  font-weight: 400;
  font-size: 21px;
  display: flex;
  flex-direction: column;
  margin-bottom: 45px;
  margin-top: 8px;
}

.author-info {
  background: #e3e3e3;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  border-radius: 12px 12px 0 0;
  border: 1px solid rgb(143, 143, 143);
}

.author-last-name {
  font-size: 17px;
}

.post-container {
  display: flex;
  flex-direction: column;
}

.post-image {
  width: 100%;
  height: auto;
}

.post-info {
  display: flex;
  flex-direction: column;
  background: #e3e3e3;
  border-radius: 0 0 12px 12px;
  padding: 15px;
  border: 1px solid rgb(143, 143, 143);
}

.post-buttons {
  display: flex;
  justify-content: space-between;
}

.left-groups {
  display: flex;
  justify-content: flex-start;
  gap: 15px;
}

.like-group,
.comment-group {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 3px;
}

.like-counter,
.comment-counter {
  font-size: 20px;
  font-weight: 400;
}

.like-button {
  width: 30px;
  height: 30px;
  background-image: url('../images/like_icon.png'); /* Default empty heart */
  background-size: contain;
  background-repeat: no-repeat;
  background-color: transparent;
  background-position: center;
  border: none;
  cursor: pointer;
}

.like-button.liked {
  background-image: url('../images/fullHeart.svg'); /* Full heart when liked */
}

.comment-button {
  width: 40px;
  height: 40px;
  background-image: url('../images/comment_icon.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-color: transparent;
  background-position: center;
  padding: none;
  border: none;
  cursor: pointer;

  &:hover {
    background-color: transparent;
  }
}

.trash-button {
  width: 50px;
  height: 50px;
  background-image: url('../images/trash_icon.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-color: transparent;
  background-position: center;
  padding: none;
  border: none;
  cursor: pointer;

  &:hover {
    background-color: transparent;
  }
}

.report-container {
  background: transparent;
  display: flex;
  justify-content: flex-end;
}

.report-button {
  width: 80%;
  color: $light-accent-color;
  text-decoration: underline;
  text-decoration-thickness: 3px;
  font-size: 15px;
  background: transparent;
  border: none;
  text-underline-offset: 4px;
  cursor: pointer;
}

.post-title-container {
  text-align: left;
  font-size: 17px;
}

.see-all-comments-message {
  text-align: left;
  font-size: 16px;
  font-weight: 500;
  padding: 5px 0px;
  background-color: transparent;
  color: black;

  &:hover {
    background-color: transparent;
  }
}

.add-a-comment-section {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
}

.comment-input {
  background-color: transparent;
  border: 0px;
  font-size: 16px;
  font-weight: 528;
  padding: 0px;

  &::placeholder {
    color: black;
  }

  &:focus {
    outline: none;
  }
}

.add-a-comment-group {
  display: flex;
  justify-content: space-between;
}

.send-button {
  width: 30px;
  height: 30px;
  background-image: url('../images/send_icon.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-color: transparent;
  background-position: center;
  padding: none;
  border: none;
  cursor: pointer;

  &:hover {
    background-color: transparent;
  }
}

.line {
  margin-top: 0.25vh;
}

///COMMENT SECTION
///
.comment-section-dark-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
}

.comment-section-container {
  padding: 5px 25px 25px 25px;
  margin-top: 150px;
  width: calc(88vw / 3);
  height: calc(100vh - 280px);
  background-color: $comment-section-background-color;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border: 1px solid black;
  border-radius: 5px;
}

.comment-top-button-container {
  display: flex;
  justify-content: flex-end;
}

.comment-exit-button {
  font-size: 30px;
  padding: 5px;
  background-color: transparent;
  background-position: center;
  padding: none;
  border: none;
  cursor: pointer;
  color: black;

  &:hover {
    background-color: transparent;
  }
}

.comment-main-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
  text-align: left;
  gap: 30px;
  height: 90%;
}

.comment-list {
  overflow-y: auto;
}

/* Hide scrollbar for Chrome, Safari, and Opera */
.comment-list::-webkit-scrollbar {
  display: none; /* Hide scrollbar */
}

/* Hide scrollbar for Internet Explorer, Edge */
.comment-list {
  -ms-overflow-style: none; /* Internet Explorer and Edge */
}

/* Hide scrollbar for Firefox */
.comment-list {
  scrollbar-width: none; /* Firefox */
}

.comment-author-last-name,
.comment-author-first-name {
  font-weight: 700;
  font-size: 15px;
}

.comment-info {
  font-size: 15px;
  font-family: 'Literata', serif;
}

.comment-author-first-name {
  margin-left: 5px;
  margin-right: 15px;
}

.comment {
  margin-bottom: 10px;
  line-height: 1.4;
}

@media (max-width: 768px) {
  .comment-section-container {
    width: calc(100vw);
    margin-left: 20px;
    margin-right: 20px;
  }
}
